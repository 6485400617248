import {IProduct, ProductsManifest} from '../../../types/galleryTypes';
import {AddToCartState} from '@wix/wixstores-client-storefront-sdk/dist/es/src/services/AddToCartService/constants';

export const canAddProductToCart = (product: IProduct, productsManifest: ProductsManifest): boolean => {
  return product.isInStock || isPreOrderState(product, productsManifest);
};

export const isAddToCartEnabled = (product: IProduct, productsManifest: ProductsManifest): boolean => {
  const currentState = productsManifest[product.id].addToCartState;
  const isEnabledState = currentState === AddToCartState.ENABLED;
  return isEnabledState || isPreOrderState(product, productsManifest);
};

const isPreOrderState = (product: IProduct, productsManifest: ProductsManifest): boolean => {
  const {addToCartState} = productsManifest[product.id];
  return addToCartState === AddToCartState.PRE_ORDER;
};
